import Image from "next/image";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper";
import Link from "next/link";
import Animate from "../Common/AnimateOnScroll";
import SliderButtons from "../Common/SliderButtons";

const CaseStudies = () => {
  return (
    <div className="max-w-screen-xl mx-auto flex flex-col items-center justify-center px-8 md:px-16 py-10 mt-10 relative">
      <Animate.ScaleIn>
        <div className="flex space-x-2 items-center fontNew">
          <p className={`font-bold text-2xl md:text-5xl text-primary `}>
            Our Case
          </p>
          <p className={`font-bold text-2xl md:text-5xl text-secondary `}>
            Studies
          </p>
        </div>
      </Animate.ScaleIn>
      <Animate.ScaleIn>
        <span className="w-[90%] text-base flex items-center justify-center font-normal  text-center mt-1 fontNew">
          Here are a few case studies highlighting successful projects we have
          undertaken.
        </span>
      </Animate.ScaleIn>
      <Swiper
        pagination={{
          dynamicBullets: true,
        }}
        freeMode={false}
        grabCursor={true}
        modules={[Pagination, Autoplay]}
        autoplay
        className="mySwiper"
        breakpoints={{
          768: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          0: {
            slidesPerView: 1,
            spaceBetween: 50,
          },
        }}
        style={{
          width: "100%",
          paddingTop: "4rem",
          paddingBottom: "4rem",
        }}
        id="case-study-slider"
      >
        <SliderButtons />
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-3 ">
          <SwiperSlide>
            <div className="relative w-[496px] h-[362px] rounded-[30px]">
              <Image
                src={"https://firebasestorage.googleapis.com/v0/b/zysoftec-f5675.appspot.com/o/services_img%2FKida%20Boot%20Web%20Mockup_11zon.jpg?alt=media&token=3ba4f1bb-9461-4aff-bff1-5200426dbfb2"}
                className="rounded-[30px]"
                width={287}
                height={433}
                alt="web development zysoftec"
              />
              <div
                className={`flex w-full absolute bottom-0 text-white font-bold text-xl p-2 px-4 fontNew`}
                style={{
                  background: "rgba(250, 112, 112, 0.75)",
                  backdropFilter: "blur(1px)",
                  borderRadius: "0px 0px 30px 30px",
                }}
              >
                Web Development
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="relative w-[496px] h-[362px] rounded-[30px]">
              <Image
                src={"https://firebasestorage.googleapis.com/v0/b/zysoftec-f5675.appspot.com/o/services_img%2FInvoice%20App%20Design%20Mockup_11zon.jpg?alt=media&token=43471c91-9099-4138-bf60-8737769ce8b3"}
                className="rounded-[30px]"
                width={287}
                height={433}
                alt="app development zysoftec"
              />
              <div
                className={`flex w-full absolute bottom-0 text-white font-bold text-xl p-2 px-4 fontNew`}
                style={{
                  background: "rgba(250, 112, 112, 0.45)",
                  backdropFilter: "blur(1px)",
                  borderRadius: "0px 0px 30px 30px",
                }}
              >
                App Development
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="relative w-[496px] h-[362px] rounded-[30px]">
              <Image
                src={"https://firebasestorage.googleapis.com/v0/b/zysoftec-f5675.appspot.com/o/services_img%2FHealthcae%20App%20Mockup_11zon.jpg?alt=media&token=c57426cb-1f84-4357-a826-f3d05ee1876b"}
                className="rounded-[30px]"
                width={287}
                height={433}
                alt="ui ux designing zysoftec"
              />
              <div
                className={`flex w-full absolute bottom-0 text-white font-bold text-xl p-2 px-4 fontNew`}
                style={{
                  background: "rgba(250, 112, 112, 0.75)",
                  backdropFilter: "blur(1px)",
                  borderRadius: "0px 0px 30px 30px",
                }}
              >
                UI UX Design
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="relative w-[496px] h-[362px] rounded-[30px]">
              <Image
                src={"https://firebasestorage.googleapis.com/v0/b/zysoftec-f5675.appspot.com/o/services_img%2Fone%20union_11zon.jpg?alt=media&token=f6c42c70-7d2d-47e9-9464-8710ab5a0e61"}
                className="rounded-[30px]"
                width={287}
                height={433}
                alt="panel development zysoftec"
              />
              <div
                className={`flex w-full absolute bottom-0 text-white font-bold text-xl p-2 px-4 fontNew`}
                style={{
                  background: "rgba(250, 112, 112, 0.75)",
                  backdropFilter: "blur(1px)",
                  borderRadius: "0px 0px 30px 30px",
                }}
              >
                Panel Development
              </div>
            </div>
          </SwiperSlide>
        </div>
      </Swiper>
      <Animate.ScaleIn>
        <Link
          href={"/case-study"}
          style={{
            background:
              "linear-gradient(280.91deg, #1A3183 28.64%, #16349F 77.71%)",
          }}
          className="px-8 py-3 flex items-center justify-center rounded-[5px] mt-10"
        >
          <span className=" text-white font-normal fontNew">View all</span>
        </Link>
      </Animate.ScaleIn>
    </div>
  );
};

export default CaseStudies;
